import { useState } from "react";
import { AppShell } from "@mantine/core";
import MarketplaceFooter from "../DefaultFooter";
import { AppOutlet } from "./AppOutlet";
import DefaultHeader from "../DefaultHeader";
import { Navigate } from "react-router-dom";
import { Helper } from "../../../utils/helper";

type MainAppShellProps = {};

export const DefaultShell: React.FC<MainAppShellProps> = ({}) => {
  const [opened, setOpened] = useState(false);
  if (Helper.getUserFromLocalstorage()) {
    return <Navigate to="/manage-store" />;
  }
  return (
    <AppShell
      styles={{
        main: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
          paddingTop: 75,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={<DefaultHeader opened={opened} setOpened={setOpened} />}
      footer={<MarketplaceFooter />}
    >
      <AppOutlet />
    </AppShell>
  );
};

export default DefaultShell;
