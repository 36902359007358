import { useState } from "react";
import { AppShell } from "@mantine/core";
import { AppOutlet } from "./AppOutlet";
import MainHeader from "../MainHeader";
import BrandNavbar from "../sidebar-navigation/BrandSidebar";
import { useMediaQuery } from "@mantine/hooks";
import { UIConfig } from "../../../styles/mantine-config";
import { Navigate } from "react-router-dom";
import { Helper } from "../../../utils/helper";
import { platform } from "../../../data/constants";

export const BrandManagerShell: React.FC<{}> = () => {
  const [opened, setOpened] = useState(false);
  const matches = useMediaQuery("(max-width: 64em)", true, {
    getInitialValueInEffect: false,
  });
  if (!Helper.getUserFromLocalstorage()) return <Navigate to="/" />;
  if (Helper.getUserFromLocalstorage()?.platform === platform.localStore) {
    return <Navigate to="/manage-store" />;
  }
  return (
    <AppShell
      styles={{
        main: {
          minHeight: "50vh",
          paddingLeft: matches !== true ? UIConfig.drawer_width : 0,
          paddingRight: 0,
          margin: 0,
          paddingTop: 80,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={<MainHeader opened={opened} setOpened={setOpened} />}
      navbar={<BrandNavbar opened={opened} setOpened={setOpened} />}
    >
      <AppOutlet />
    </AppShell>
  );
};

export default BrandManagerShell;
