import DefaultShell from "../../components/ui/template/DefaultShell";
import { Routes, Route, Navigate } from "react-router-dom";
import StoreManagerShell from "../../components/ui/template/StoreManagerShell";
import BrandManagerShell from "../../components/ui/template/BrandManagerShell";
import { lazy } from "react";
import { BrandRegisterationScreen } from "../../pages/registerations/BrandRegisterationScreen";
import { StoreRegisterationScreen } from "../../pages/registerations/StoreRegisterationScreen";
import NewAdScreen from "../../pages/manage-brand/new-ads";
import useScrollToTop from "../../hooks/useScrollToTop";

const StoreDashboardScreen = lazy(
  () => import("../../pages/manage-store/dashboard")
);
const BrandDashboardScreen = lazy(
  () => import("../../pages/manage-brand/dashboard")
);
const ManageStoreProductsScreen = lazy(
  () => import("../../pages/manage-store/ManageProductsScreen")
);
const ManageShopsScreen = lazy(
  () => import("../../pages/manage-store/ManageShopsScreen")
);
const StoreSettingsScreen = lazy(
  () => import("../../pages/manage-store/StoreSettingsScreen")
);
const InvoicesScreen = lazy(() => import("../../pages/manage-brand/invoices"));
const AdsScreen = lazy(() => import("../../pages/manage-brand"));
const WalletScreen = lazy(() => import("../../pages/manage-brand/wallet"));
const ManageBrandProductsScreen = lazy(
  () => import("../../pages/manage-brand/ManageBrandProductsScreen")
);

const LoginScreen = lazy(
  () => import("../../pages/authentication/LoginScreen")
);
const RegisterationScreen = lazy(
  () => import("../../pages/authentication/RegisterationScreen")
);

export default function MainAppRoutes() {
  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={<DefaultShell />}>
        <Route path="login" element={<LoginScreen />} />
        <Route path="register" element={<RegisterationScreen />} />
        <Route index element={<RegisterationScreen />} />
      </Route>
      <Route path="/" element={<BrandManagerShell />}>
        <Route path="manage-brand/wallet" element={<WalletScreen />} />
        <Route path="manage-brand/ads/new" element={<NewAdScreen />} />
        <Route path="manage-brand/ads" element={<AdsScreen />} />
        <Route
          path="manage-brand/products"
          element={<ManageBrandProductsScreen />}
        />
        <Route path="manage-brand/orders" element={<InvoicesScreen />} />
        <Route path="manage-brand" element={<BrandDashboardScreen />} />
      </Route>
      <Route path="/" element={<StoreManagerShell />}>
        <Route path="manage-store/products">
          <Route index element={<ManageStoreProductsScreen />} />
        </Route>
        <Route path="manage-store/settings" element={<StoreSettingsScreen />} />
        <Route path="manage-store/shops" element={<ManageShopsScreen />} />
        <Route path="manage-store" element={<StoreDashboardScreen />} />
      </Route>
      <Route
        path="register-local-store"
        element={<StoreRegisterationScreen />}
      />
      <Route
        path="register-brand-store"
        element={<BrandRegisterationScreen />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
