import {
  Box,
  Center,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Button,
} from "@mantine/core";
import { TickCircle } from "iconsax-react";

export default function ApprovalCheck({
  openModal,
  onCloseModal,
  message,
}: {
  openModal: boolean;
  message: string;
  onCloseModal: () => void;
}) {
  const theme = useMantineTheme();
  return (
    <Box>
      <Modal opened={openModal} onClose={() => onCloseModal()}>
        {" "}
        <Stack>
          <Box>
            <Title
              sx={{ fontSize: 32, textAlign: "center", marginBottom: "16px" }}
            >
              Great!!
            </Title>
            <Text sx={{ textAlign: "center" }}>{message}</Text>
          </Box>

          <Center>
            <TickCircle
              variant="Bold"
              color={theme.colors.blue[5]}
              size={150}
            />
          </Center>
          <Button
            onClick={() => {
              window.location.href = window.location.origin + "/manage-store";
            }}
          >
            Dashboard
          </Button>
        </Stack>
      </Modal>
    </Box>
  );
}
