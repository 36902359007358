import { useState } from "react";
import { AppShell, LoadingOverlay } from "@mantine/core";
import { AppOutlet } from "./AppOutlet";
import MainHeader from "../MainHeader";
import { useMediaQuery } from "@mantine/hooks";
import { UIConfig } from "../../../styles/mantine-config";
import MarketNavbar from "../sidebar-navigation/StoreSidebar";
import { Navigate } from "react-router-dom";
import { Helper } from "../../../utils/helper";
import { useGetLocalShops } from "../../../hooks/apiQueryHooks/marketQueryHooks";
import { platform } from "../../../data/constants";

export const StoreManagerShell: React.FC<{}> = () => {
  const [opened, setOpened] = useState(false);
  const { data: shops, isLoading: isLoadingShops } = useGetLocalShops();
  const matches = useMediaQuery("(max-width: 64em)", true, {
    getInitialValueInEffect: false,
  });
  if (!Helper.getUserFromLocalstorage()) return <Navigate to="/" />;
  if (Helper.getUserFromLocalstorage()?.platform === platform.brand) {
    return <Navigate to="/manage-brand" />;
  }
  if (isLoadingShops) return <LoadingOverlay visible={true} />;
  if (
    !isLoadingShops &&
    shops &&
    !shops?.some((element) => element?.status === "isApproved")
  )
    return <Navigate to="/register-local-store" />;
  return (
    <AppShell
      styles={{
        main: {
          minHeight: "50vh",
          paddingLeft: matches !== true ? UIConfig.drawer_width : 0,
          paddingRight: 0,
          margin: 0,
          paddingTop: 60,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="xs"
      header={<MainHeader opened={opened} setOpened={setOpened} />}
      navbar={<MarketNavbar opened={opened} setOpened={setOpened} />}
    >
      <AppOutlet />
    </AppShell>
  );
};

export default StoreManagerShell;
