import * as yup from "yup";
import { Helper } from "../../utils/helper";
import { PlatformType } from "../../types";

export const loginSchema = yup.object({
  email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  password: yup
    .string()
    .required("Please enter a password.")
    .matches(
      Helper.passwordRegex,
      "Your password needs to be at least 8 characters, One Uppercase, One Number and One Special Character."
    ),
  platform: yup.string<PlatformType>().required("Please select a platform."),
});
export const addShopSchema = yup.object({
  twitterUrl: yup.string(),
  facebookUrl: yup.string(),
  shopHandle: yup.string().required("Please enter shop handle"),
  shopName: yup.string().required("Please enter shop name"),
  shopAddress: yup.string().required("Please enter shop address"),
  marketRef: yup.string().required("Please select market"),
  stateRef: yup.string().required("Please select state"),
  businessCategoryRef: yup.string().required("Please select business category"),
  instagramUrl: yup.string(),
  logo: yup.string(),
  images: yup.mixed<File>(),
});
export const updateShopSchema = yup.object({
  twitterUrl: yup.string(),
  facebookUrl: yup.string(),
  shopHandle: yup.string(),
  shopName: yup.string(),
  shopAddress: yup.string(),
  shopRef: yup.string().required("Please select shop"),
  instagramUrl: yup.string(),
  logo: yup.string(),
});
export const addProductSchema = yup.object({
  productName: yup.string().required("Please enter product name"),
  price: yup.number().required("Please enter price"),
  businessCategoryRef: yup.string().required("Please select business category"),
  shopRef: yup.string().required("Please select shop"),
  description: yup.string().required("Please enter description"),
  images: yup.array().of(yup.mixed<File>().required("Please choose an image")),
});
export const updateProductSchema = yup.object({
  productName: yup.string(),
  productRef: yup.string(),
  price: yup.number(),
  businessCategoryRef: yup.string(),
  description: yup.string(),
  images: yup.array().of(yup.mixed<File>().required("Please choose an image")),
  shopRef: yup.string(),
});
export const signupSchema = yup.object({
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name "),
  gender: yup.string().required("Please select gender "),
  phoneNumber: yup
    .string()
    .required("Please enter phone")
    .matches(Helper.phoneRegExp, "Please enter valid phone number"),
  email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  password: yup
    .string()
    .required("Please enter a password.")
    .matches(
      Helper.passwordRegex,
      "Your password needs to be at least 8 characters, One Uppercase, One Number and One Special Character."
    ),
  confirmPassword: yup
    .string()
    .required("Please enter a password.")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  platform: yup.string<PlatformType>().required("Please select a platform."),
  verifyWithEmail: yup.boolean(),
});

export const otpSchema = yup.object({
  otp: yup
    .string()
    .test(function (value) {
      return value?.length === 6;
    })
    .required("Please enter otp"),
});
