import { Avatar, Box, Group, Select, Text } from "@mantine/core";
import { observer } from "mobx-react";
import { forwardRef } from "react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const ActiveBusinessSelector = observer(() => {
  return (
    <Box>
      <Select
        sx={{ width: "300px" }}
        data={[].map((element) => {
          return {
            //@ts-ignore
            value: element.id + "",
            //@ts-ignore
            label: element.name,
            //@ts-ignore
            image: element.logo,
            //@ts-ignore
            description: element.shopAddress,
          };
        })}
        value={""}
        itemComponent={SelectItem}
        onChange={() => {}}
      />
    </Box>
  );
});
