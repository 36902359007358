import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseMutationResult, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";

import { ObjectSchema } from "yup";
import { FormDataMutateType } from "../types";
import { Helper } from "../utils/helper";

export const useFormDataAndApiMutateHandler = <
  T extends FormDataMutateType,
  K extends Record<string, any>
>(
  validator: ObjectSchema<T>,
  apiMutateHook: (options?: {}) => Omit<
    UseMutationResult<any, unknown, T, unknown>,
    "reset"
  >,
  {
    successMsg = "Action completed successfully",
    next = () => {},
    queryKey = "",
  }: {
    successMsg?: string;
    next?: (res: K) => void;
    queryKey?: string;
  } = {}
) => {
  const queryClient = useQueryClient();
  const { ...formProps } = useForm({
    resolver: yupResolver(validator),
    mode: "onChange",
  });
  const { ...mutateResult } = apiMutateHook({
    onSuccess: (res: K) => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
      next(res);
      showNotification({ message: successMsg });
    },
    onError: (err: any) => {
      showNotification({
        message: Helper.getError(err?.response),
      });
    },
  });
  return {
    ...formProps,
    ...mutateResult,
  };
};
