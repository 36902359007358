import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Box,
  Stack,
  Group,
  ActionIcon,
} from "@mantine/core";
import { ShopAdd } from "iconsax-react";
import { Link } from "react-router-dom";
import { UserPrimaryActions } from "./AccountSecondaryActions";
import { ActiveBusinessSelector } from "./ActiveBusiness";
import { logo } from "../../assets/icons";
import { platform } from "../../data/constants";
import { Helper } from "../../utils/helper";

const MainHeader: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  let theme = useMantineTheme();
  return (
    <Header
      height={60}
      sx={(theme) => {
        return {
          borderBottom: "0px solid gray",
          boxShadow: theme.shadows.md,
        };
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          padding: "0 28px",
        }}
      >
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Stack
            align={"center"}
            justify="center"
            px="md"
            sx={{ width: "100%" }}
          >
            <Group
              sx={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Group>
                <Burger
                  opened={opened}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xs"
                  onClick={() => setOpened((o: any) => !o)}
                />
                <img src={logo} alt="React Logo" style={{ height: 20 }} />
              </Group>
              <UserPrimaryActions />
            </Group>
          </Stack>
        </MediaQuery>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Group position="apart" sx={{ width: "100%" }}>
            <Box
              sx={() => {
                return {
                  height: "100%",
                  width: "250px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                };
              }}
              p="md"
            >
              <Link to={"/"}>
                <img src={logo} alt="React Logo" style={{ height: 40 }} />
              </Link>
            </Box>
            <ActiveBusinessSelector />
            <Group>
              <Link
                to={
                  Helper.getUserFromLocalstorage()?.platform ===
                  platform.localStore
                    ? "/register-local-store"
                    : "register-brand-store"
                }
              >
                <ActionIcon color={"blue"}>
                  <ShopAdd />
                </ActionIcon>
              </Link>
              <UserPrimaryActions />
            </Group>
          </Group>
        </MediaQuery>
      </div>
    </Header>
  );
};

export default MainHeader;
