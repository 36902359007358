import axios from "axios";
import { Helper } from "../../utils/helper";

export function publicRequest(baseURL: string) {
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": `application/json`,
    },
  });
}
export function privateRequest(baseURL: string) {
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": `application/json`,
      Authorization:
        `Bearer ${Helper.getUserFromLocalstorage()?.token}` ?? undefined,
    },
  });
}
