import {
  TextInput,
  Text,
  Button,
  Title,
  Stack,
  Container,
  Grid,
  Group,
  Select,
  Textarea,
  Box,
  LoadingOverlay,
  FileInput,
} from "@mantine/core";
import { Facebook, Instagram } from "iconsax-react";
import { IconBrandTwitter } from "@tabler/icons";
import { RenderPlatformBanner } from "../../components/ui/Banners";
import { useFormDataAndApiMutateHandler } from "../../hooks/useFormDataAndApiMutateHandler";
import { Controller, SubmitHandler, useWatch } from "react-hook-form";
import { IAddShopPayload } from "../../types";
import { addShopSchema } from "../../lib/yup/validations";
import {
  useAddShop,
  useGetBusinessCategories,
  useGetLocalShops,
  useGetMarkets,
  useGetStates,
} from "../../hooks/apiQueryHooks/marketQueryHooks";
import { Helper } from "../../utils/helper";
import {
  useCreateSelectOptionsForMarkets,
  useCreateSelectOptionsForBusinessCategories,
  useCreateSelectOptionsForStates,
} from "../../hooks/useCreateSelectOptions";
import ApprovalCheck from "./ApprovalCheck";
import { useEffect, useState } from "react";

export const StoreRegisterationScreen = () => {
  let [showApprovalModal, setShowApprovalModal] = useState(false);
  let [message, setMessage] = useState("");
  let [isAddedShop, setAddedShop] = useState(false);
  const { data: businesCategories } = useGetBusinessCategories();
  const { data: states } = useGetStates();
  const { data: shops, isLoading: isLoadingShops } = useGetLocalShops();

  const next = () => {
    setMessage(`We have received your information. We would respond on the
      approval in 24hours`);
    setShowApprovalModal(true);
    setAddedShop(true);
  };
  const {
    register,
    handleSubmit,
    isLoading,
    formState: { isDirty, errors, isValid },
    mutate,
    control,
    getValues,
    reset,
  } = useFormDataAndApiMutateHandler(addShopSchema, useAddShop, {
    queryKey: "GET_LOCALSHOPS_QUERY",
    next,
  });
  useWatch({
    control,
    defaultValue: {
      stateRef: "",
    },
  });
  const { data: markets } = useGetMarkets(getValues("stateRef"), {
    enabled: !!getValues("stateRef"),
    onSuccess: () => reset({ marketRef: "" }),
  });
  useEffect(() => {
    if (
      !isAddedShop &&
      !isLoadingShops &&
      shops &&
      !shops?.every((element) => element?.status === "isApproved")
    ) {
      setShowApprovalModal(true);
      setMessage(
        `${
          shops.length > 1
            ? "One of your shops awaits approval"
            : "Your shop awaits approval"
        }`
      );
    }
  }, [shops, isLoadingShops]);

  const onSubmit: SubmitHandler<IAddShopPayload> = (data) => {
    mutate(data);
  };

  if (isLoadingShops) return <LoadingOverlay visible={true} />;
  return (
    <Box>
      <ApprovalCheck
        openModal={showApprovalModal}
        onCloseModal={() => {
          setShowApprovalModal(false);
        }}
        message={message}
      />
      <RenderPlatformBanner />
      <Container py="42px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={"xl"}>
            <Title size={"md"}>REGISTER LOCAL STORE</Title>
            <Box>
              <Title size={"md"}>Personal Details</Title>
              <Text color={"gray"}>
                {Helper.getUserFromLocalstorage()?.user?.email ?? "N/A"}
                <br />
                {Helper.getUserFromLocalstorage()?.user?.phone ?? "N/A"}
                <br />
                {Helper.getUserFromLocalstorage()?.user?.gender ?? "N/A"}
              </Text>
            </Box>
            <Grid>
              <Grid.Col md={6}>
                <Controller
                  name="stateRef"
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <Select
                      required
                      placeholder="States"
                      id="states"
                      onBlur={onBlur}
                      label="States"
                      onChange={(option) => onChange(option)}
                      data={useCreateSelectOptionsForStates(states || []) || []}
                      size="md"
                    />
                  )}
                />
              </Grid.Col>
              {markets && (
                <Grid.Col md={3}>
                  <Controller
                    name="marketRef"
                    control={control}
                    render={({ field: { onBlur, onChange } }) => (
                      <Select
                        required
                        placeholder="Market"
                        id="marketRef"
                        onBlur={onBlur}
                        label="Market"
                        onChange={(option) => onChange(option)}
                        data={
                          useCreateSelectOptionsForMarkets(markets || []) || []
                        }
                        size="md"
                      />
                    )}
                  />
                </Grid.Col>
              )}
              <Grid.Col md={markets ? 3 : 6}>
                <Controller
                  name="businessCategoryRef"
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <Select
                      required
                      placeholder="Business Category"
                      id="businessCategory"
                      onBlur={onBlur}
                      label="Business Category"
                      onChange={(option) => onChange(option)}
                      data={
                        useCreateSelectOptionsForBusinessCategories(
                          businesCategories || []
                        ) || []
                      }
                      size="md"
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col md={6}>
                <TextInput
                  required
                  label="Business Name"
                  placeholder="Type here"
                  size="md"
                  {...register("shopName")}
                />
                {errors?.shopName && (
                  <p style={{ color: "red" }}>{errors.shopName.message}</p>
                )}
              </Grid.Col>
              <Grid.Col md={6}>
                <TextInput
                  required
                  label="Handle"
                  placeholder="Type here"
                  size="md"
                  {...register("shopHandle")}
                />
                {errors?.shopHandle && (
                  <p style={{ color: "red" }}>{errors.shopHandle.message}</p>
                )}
              </Grid.Col>
              <Grid.Col md={12}>
                <Textarea
                  required
                  label="Shop Address"
                  placeholder="Type here"
                  size="md"
                  {...register("shopAddress")}
                />
                {errors?.shopAddress && (
                  <p style={{ color: "red" }}>{errors.shopAddress.message}</p>
                )}
              </Grid.Col>
              <Grid.Col md={6}>
                <Controller
                  name="images"
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <FileInput
                      accept="image/png,image/jpeg"
                      value={value}
                      onChange={(payload) => onChange(payload)}
                      onBlur={onBlur}
                      label="Add Store Image"
                      size="md"
                    />
                  )}
                />
                {errors?.images && (
                  <p style={{ color: "red" }}>{errors.images.message}</p>
                )}
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4}>
                <Group mb={"8px"}>
                  <Facebook variant="Bold" />
                  <Text>Facebook</Text>
                </Group>
                <TextInput
                  placeholder="Facebook URL"
                  size="md"
                  {...register("facebookUrl")}
                />
              </Grid.Col>
              <Grid.Col md={4}>
                <Group mb={"8px"}>
                  <IconBrandTwitter />
                  <Text>Twitter</Text>
                </Group>
                <TextInput
                  placeholder="Twitter URL"
                  size="md"
                  {...register("twitterUrl")}
                />
              </Grid.Col>
              <Grid.Col md={4}>
                <Group mb={"8px"}>
                  <Instagram variant="Bold" />
                  <Text>Instagram</Text>
                </Group>
                <TextInput
                  placeholder="Instagram URL"
                  size="md"
                  {...register("instagramUrl")}
                />
              </Grid.Col>
            </Grid>

            <Button
              fullWidth
              size="lg"
              type="submit"
              loading={isLoading}
              disabled={!isValid || !isDirty}
            >
              Create Shop
            </Button>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};
