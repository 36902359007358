import { Box, Group, Paper, Stack, Title, Text } from "@mantine/core";
import { FancySectionWrapper } from "../../components/ui/FancySectionWrapper";
import { RenderAds } from "./RenderAds";
import { RenderStatsMain } from "../../components/ui/render-stats";

export const NewAdScreen = () => {
  return (
    <Box style={{ overflow: "hidden !important" }}>
      <Paper
        p={"18px"}
        py="32px"
        sx={() => {
          return { width: "100%", background: "white" };
        }}
        shadow="md"
      >
        <Stack>
          <Group position="apart">
            <Box>
              <Title sx={{ fontSize: 24 }}>New Ads</Title>
              <Text>Select the product you want to run an advert for</Text>
            </Box>
          </Group>

          <FancySectionWrapper>
            <Group>
              <Box sx={{ flex: 1 }}>
                <RenderStatsMain
                  stats={[
                    { label: "Active Adsd", value: "0" },
                    { label: "Impressions", value: "0" },
                    { label: "Views", value: "0" },
                  ]}
                />
              </Box>
            </Group>
          </FancySectionWrapper>

          <FancySectionWrapper>
            {/* <RenderAds ads={[]} /> */}
          </FancySectionWrapper>
        </Stack>
      </Paper>
    </Box>
  );
};

export default NewAdScreen;
