import { Avatar, Stack, Group, Button, Menu, Box, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { platform } from "../../data/constants";
import { Helper } from "../../utils/helper";

export const UserPrimaryActions = () => {
  const navigate = useNavigate();
  return (
    <Menu width={"280px"}>
      <Menu.Target>
        <Avatar
          src={""}
          size="md"
          style={{ cursor: "pointer" }}
          radius={"xl"}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <Stack align={"center"} p="12px">
          <Avatar size={"lg"} sx={{ borderRadius: 50 }} src="" />
          <Box sx={{ textAlign: "center" }}>
            <Text sx={{ fontWeight: "bold" }}>
              {`${Helper.getUserFromLocalstorage()?.user?.firstname} ${
                Helper.getUserFromLocalstorage()?.user?.lastname
              }` ?? "N/A"}
            </Text>
            <Text>{Helper.getUserFromLocalstorage()?.user?.email}</Text>
          </Box>

          <Group>
            <Button variant="outline">Edit Profile</Button>
            <Button
              variant="outline"
              color={"red"}
              onClick={() => {
                Helper.clearLocalStorage();
              }}
            >
              Sign Out
            </Button>
          </Group>

          {Helper.getUserFromLocalstorage()?.platform ===
          platform.localStore ? (
            <Button
              onClick={async () => {
                navigate("/register-local-store");
              }}
              fullWidth
            >
              Add another shop
            </Button>
          ) : (
            <Button
              onClick={async () => {
                navigate("/register-brand-store");
              }}
              fullWidth
            >
              Add another brand store
            </Button>
          )}
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
