import { Stack, Box } from "@mantine/core";
import {} from "iconsax-react";
import React from "react";

export const FancySectionWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box>
      <Stack
        sx={() => {
          return {
            padding: 12,
            borderRadius: 10,
            background: "white",
            border: "2px solid rgba(0, 0, 0, 0.16)",
          };
        }}
      >
        {children}
      </Stack>
    </Box>
  );
};
