import { Title, Box, Grid, Text, useMantineTheme } from "@mantine/core";

type RenderStatsMainProps = {
  stats: {
    label: string;
    value: string;
  }[];
};

export function RenderStatsMain({ stats }: RenderStatsMainProps) {
  const theme = useMantineTheme();
  return (
    <Grid sx={{ flex: 1 }} align={"stretch"}>
      {stats.map((element, index) => {
        return (
          <Grid.Col md={4} key={element.label + index}>
            <Box
              sx={{
                textAlign: "center",
                border: `1px solid ${theme.colors.gray[3]}`,
                height: "100%",
                borderRadius: 8,
              }}
              p="24px"
            >
              <Title sx={{ fontWeight: "bold", fontSize: 24 }}>
                {element.value}
              </Title>
              <Text size={"sm"}>{element.label}</Text>
            </Box>
          </Grid.Col>
        );
      })}
    </Grid>
  );
}
