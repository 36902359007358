import {
  Activity,
  Diagram,
  Receipt1,
  Setting2,
  Tag,
  Wallet,
  ShopAdd,
} from "iconsax-react";
import { LinksGroupProps } from "./NavigationLinks";

export const storeNavigationLinks: LinksGroupProps[] = [
  { label: "Overview", icon: Activity, link: "/manage-store" },
  { label: "Products", icon: Tag, link: "/manage-store/products" },
  { label: "Shops", icon: ShopAdd, link: "/manage-store/shops" },
  { label: "Settings", icon: Setting2, link: "/manage-store/settings" },
];

export const brandBusinessNavigationLinks: LinksGroupProps[] = [
  { label: "Overview", icon: Activity, link: "/manage-brand" },
  { label: "Products", icon: Tag, link: "/manage-brand/products" },
  { label: "Invoices", icon: Receipt1, link: "/manage-brand/orders" },
  { label: "My Ads", icon: Diagram, link: "/manage-brand/ads" },
  { label: "Z-Wallet", icon: Wallet, link: "/manage-brand/wallet" },
];
