import { privateRequest, publicRequest } from "../lib/axios/axiosRequest";
import {
  BusinessCategoryOption,
  IAddProductPayload,
  IAddShopPayload,
  IUpdateProductPayload,
  IUpdateShopPayload,
  MarketOption,
  ServerGetShopPayload,
  ServerProductPayload,
  stateOption,
} from "../types";
import {
  category,
  market,
  productAdmin,
  shopAdmin,
  state,
} from "../utils/apiServiceController";
import { BASE_URL } from "../utils/apiUrls";
import { Helper } from "../utils/helper";

export const addLocalShop = async (payload: IAddShopPayload): Promise<any> => {
  const data = Helper.createFormData(Helper.clearEmptyField(payload));

  const res = await privateRequest(BASE_URL!).post(`${shopAdmin}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};
export const updateLocalShop = async (
  payload: IUpdateShopPayload
): Promise<any> => {
  const { shopRef, ...others } = payload;
  const res = await privateRequest(BASE_URL!).patch(`${shopAdmin}/${shopRef}`, {
    ...Helper.clearEmptyField(others),
  });
  return res.data;
};
export const addProduct = async (payload: IAddProductPayload): Promise<any> => {
  const { shopRef, images, ...other } = payload;
  const data = Helper.createFormData(Helper.clearEmptyField(other));

  if (images && images.length > 0) {
    images.forEach((image) => {
      data.append(`images[]`, image);
    });
  }

  const res = await privateRequest(BASE_URL!).post(`${productAdmin}`, data, {
    headers: {
      "x-shop-ref": shopRef,
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const updateProduct = async (
  payload: IUpdateProductPayload
): Promise<any> => {
  const { productRef, images, shopRef, ...other } = payload;
  const data = Helper.createFormData(Helper.clearEmptyField(other));
  console.log(images);
  if (images && images.length > 0) {
    images.forEach((image) => {
      data.append(`images[]`, image);
    });
  }

  const res = await privateRequest(BASE_URL!).patch(
    `${productAdmin}/${productRef}`,
    data,
    {
      headers: {
        "x-shop-ref": shopRef,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};
export const deleteProduct = async (
  payload: Pick<IUpdateProductPayload, "productRef" | "shopRef">
): Promise<any> => {
  const { productRef, shopRef } = payload;
  const res = await privateRequest(BASE_URL!).delete(
    `${productAdmin}/${productRef}`,
    {
      headers: {
        "x-shop-ref": shopRef,
      },
    }
  );
  return res.data;
};
export const getLocalShops = async (): Promise<ServerGetShopPayload[]> => {
  const res = await privateRequest(BASE_URL!).get(`${shopAdmin}`);
  return res.data.responseBody;
};

export const getProducts = async (): Promise<ServerProductPayload[]> => {
  const res = await privateRequest(BASE_URL!).get(`${productAdmin}`);
  return res.data.responseBody.content;
};
export const getBusinessCategories = async (): Promise<
  BusinessCategoryOption[]
> => {
  const res = await publicRequest(BASE_URL!).get(`${category}`);
  return res.data.responseBody;
};
export const getStates = async (): Promise<stateOption[]> => {
  const res = await privateRequest(BASE_URL!).get(`${state}`);
  return res.data.responseBody;
};
export const getMarkets = async (stateRef: string): Promise<MarketOption[]> => {
  const res = await publicRequest(BASE_URL!).get(`${market}/state/${stateRef}`);
  return res.data.responseBody;
};
