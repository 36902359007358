import {
  BusinessCategoryOption,
  MarketOption,
  ServerGetShopPayload,
  shopOption,
  stateOption,
} from "../types";

export const useCreateSelectOptionsForMarkets = (arr: MarketOption[]) => {
  if (arr) {
    return arr?.map(({ marketRef, name }) => {
      return {
        value: marketRef,
        label: name,
      };
    });
  }
};
export const useCreateSelectOptionsForBusinessCategories = (
  arr: BusinessCategoryOption[]
) => {
  if (arr) {
    return arr?.map(({ businessCategoryRef, name }) => {
      return {
        value: businessCategoryRef,
        label: name,
      };
    });
  }
};
export const useCreateSelectOptionsForStates = (arr: stateOption[]) => {
  if (arr) {
    return arr?.map(({ stateRef, name }) => {
      return {
        value: stateRef,
        label: name,
      };
    });
  }
};
export const useCreateSelectOptionsForShops = (arr: ServerGetShopPayload[]) => {
  if (arr) {
    return arr
      ?.filter(({ status }) => status === "isApproved")
      .map(({ shopRef, name }) => {
        return {
          value: shopRef,
          label: name,
        };
      });
  }
};
