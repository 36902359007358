import {
  addLocalShop,
  addProduct,
  deleteProduct,
  getBusinessCategories,
  getLocalShops,
  getMarkets,
  getProducts,
  getStates,
  updateLocalShop,
  updateProduct,
} from "@services/marketApiRequest";
import { useMutation, useQuery } from "react-query";

export const useAddShop = (options = {}) => {
  return useMutation(addLocalShop, {
    mutationKey: "ADD_SHOP_QUERY",
    ...options,
  });
};
export const useUpdateShop = (options = {}) => {
  return useMutation(updateLocalShop, {
    mutationKey: "UPDATE_SHOP_QUERY",
    ...options,
  });
};
export const useAddProduct = (options = {}) => {
  return useMutation(addProduct, {
    mutationKey: "ADD_PRODUCT_QUERY",
    ...options,
  });
};
export const useUpdateProduct = (options = {}) => {
  return useMutation(updateProduct, {
    mutationKey: "UPDATE_PRODUCT_QUERY",
    ...options,
  });
};
export const useDeleteProduct = (options = {}) => {
  return useMutation(deleteProduct, {
    mutationKey: "DELETE_PRODUCT_QUERY",
    ...options,
  });
};
export const useGetBusinessCategories = (options = {}) => {
  return useQuery(
    ["GET_BUSINESS_CATEGORIES_QUERY"],
    () => getBusinessCategories(),
    {
      ...options,
    }
  );
};
export const useGetMarkets = (stateRef: string, options = {}) => {
  return useQuery(["GET_MARKET_QUERY", stateRef], () => getMarkets(stateRef), {
    ...options,
  });
};
export const useGetStates = (options = {}) => {
  return useQuery(["GET_STATE_QUERY"], () => getStates(), {
    ...options,
  });
};
export const useGetLocalShops = (options = {}) => {
  return useQuery(["GET_LOCALSHOPS_QUERY"], () => getLocalShops(), {
    ...options,
  });
};
export const useGetProducts = (options = {}) => {
  return useQuery(["GET_PRODUCTS_QUERY"], () => getProducts(), {
    ...options,
  });
};
