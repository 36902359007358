import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { LoadingOverlay, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import MainAppRoutes from "./router/MainAppRoutes";
import { MaintineThemeConfig } from "../styles/mantine-config";
import { ModalsProvider } from "@mantine/modals";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../config/queryClient";

export default function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={MaintineThemeConfig}
          withGlobalStyles
          withNormalizeCSS
        >
          <ModalsProvider>
            <NotificationsProvider>
              <BrowserRouter>
                <Suspense fallback={<LoadingOverlay visible={true} />}>
                  <MainAppRoutes />
                </Suspense>
              </BrowserRouter>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
}
