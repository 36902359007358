import { UserDetails } from "../types";
import { USER_AUTH_STORAGE } from "./apiUrls";

export class Helper {
  public static readonly passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  public static readonly phoneRegExp =
    /^((\+[1-9][ \\-]*)|(\\([0-9]\\)[ \\-]*)|([0-9])[ \\-]*)*?[0-9]?[ \\-]*[0-9]?$/;
  public static readonly nameRegExp = /^[a-zA-Z-]+$/;

  public static isPageActive(arr: string[]) {
    return arr.includes(window.location.pathname);
  }
  public static getUniqueReference(prefix: string) {
    return `${prefix}${(
      Date.now().toString(36) + Math.random().toString(36).substring(2)
    ).toUpperCase()}`;
  }
  public static preloadImage(src: string) {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = src;
    });
  }
  public static formatPhoneNumber(phoneNumberString: string) {
    phoneNumberString = phoneNumberString.replace(/[^\d]/g, "");
    return phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  public static setUserToLocalstorage(user: UserDetails) {
    sessionStorage.setItem(USER_AUTH_STORAGE, JSON.stringify(user));
  }
  public static clearLocalStorage() {
    sessionStorage.removeItem(USER_AUTH_STORAGE);
    window.location.reload();
  }
  public static getUserFromLocalstorage() {
    const user = sessionStorage.getItem(USER_AUTH_STORAGE);
    if (user) return JSON.parse(user) as UserDetails;
    return null;
  }
  public static formatCurrency(val: number) {
    return (
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
      }).format(val) ?? " --"
    );
  }
  public static formatDate(val: string) {
    return new Date(val).toLocaleDateString("en-us", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  public static useManipulateSelectOptionsArrays = <
    T extends Record<string, any>
  >(
    arr: T[]
  ) => {
    if (arr) {
      return arr?.map((element: T) => {
        return {
          value: element.name,
          label: element.name,
        };
      });
    }
  };
  public static getError = (err: any) => {
    const { responseMessage } = err?.data || {};

    let errorMessage: string = "";

    if (err) {
      if (responseMessage) errorMessage = responseMessage;
      else errorMessage = "Error Process Request. Try again.";
    } else errorMessage = "Error Process Request. Try again.";

    return errorMessage;
  };
  public static clearEmptyField<T extends Record<string, any>>(values: T) {
    let result: T = {} as T;
    for (let key in values) {
      const value = values[key];
      if (value) {
        result[key] = value;
      }
    }
    return result;
  }
  public static createFormData<T extends Record<string, any>>(values: T) {
    const data = new FormData();
    for (let key in values) {
      key && data.append(`${key}`, values[key]);
    }
    return data;
  }
  public static Create(data: any) {}
}
